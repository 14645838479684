export const state = () => ({
  loginToken: undefined,
})

export const mutations = {
  initialize(state) {
    state.loginToken = this.$cookies.get('auth-bearer')
  },
}

export const getters = {
  isAuthenticated: (state) => {
    return state.loginToken !== undefined
  },

  accessToken: (state) => {
    return state.loginToken
  },
}
