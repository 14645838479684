import Vue from 'vue'
import EndianUiComponents from 'eos-ui-core'
import 'eos-ui-core/dist/eos-ui-core.css'

const components = EndianUiComponents.components
for (let i = 0; i < components.length; i++) {
  const element = components[i]
  try {
    Vue.component(element.name, element)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      'Error while importing a Endian component',
      e,
      element.name,
      element
    )
    continue
  }
}

const mixins = EndianUiComponents.mixins
for (let i = 0; i < mixins.length; i++) {
  const element = mixins[i]
  try {
    Vue.mixin(element)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      'Error while importing a Endian mixin',
      e,
      element.name,
      element
    )
    continue
  }
}
