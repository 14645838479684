
import fallbackNavigationMenu from '~/fallbackNavigationMenu.json'

export default {
  data() {
    return {
      fallbackNavigationMenu,
      env: process.env.NODE_ENV,
    }
  },
}
